import React from "react"
import HeaderMysql from "./HeaderMysql"
import { Link } from "gatsby"
import PlansMySQLDatabase from "./PlansMySQLDatabase"
import FeaturesMySQL from "./FeaturesMysql"
import Seo from "../../../components/seo"
import FaqMySQL from "./FaqMySQL"

const MysqlDatabasePage = () => {
  return (
    <div className="content bg">
      <Seo
        title="MySQL Cloud Database for Billing Software - Motherepos"
        desc="Motherepos offers MySQL Cloud Database for Billing Software at competitive prices."
        keywords="MySQL Cloud Database, pos database, billing software cloud database"
      />
      <HeaderMysql />
      <section className="pricing">
        <div className="container-xl">
          <div className="choose-hosting-server text-center">
            <ul className="nav nav-pills justify-content-center my-3">
              <li className="nav-item">
                <Link
                  className="nav-link active btn btn-outline-dark"
                  to="/cloud/mysql-database/"
                >
                  MySQL
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link btn btn-outline-dark"
                  to="/cloud/mssql-database/"
                >
                  MSSQL
                </Link>
              </li>
            </ul>
          </div>
          <PlansMySQLDatabase />
        </div>
      </section>
      <FeaturesMySQL />
      <FaqMySQL />
    </div>
  )
}

export default MysqlDatabasePage
