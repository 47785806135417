import React from "react"
import { Link } from "gatsby"
const FaqLinuxHosting = () => {
  return (
    <>
      <section className="faq-general">
        <div className="container-xl">
          <div className="container-xl">
            <h4 className="fea-heading text-dark-blue mb-4">
              Frequently Asked Questions About Linux hosting
            </h4>
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    What is Shared Linux Web hosting?
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    In
                    <Link to="/hosting/linux-web-hosting/">
                      Shared Linux Web Hosting
                    </Link>
                    , multiple clients are hosted on a single server i.e. the
                    clients share the server's resources. This helps reduce the
                    cost, since the cost of the server and its resources are
                    spread over all the clients/packages hosted on the server.
                    <Link to="/hosting/linux-web-hosting/">Shared Hosting</Link>
                    is perfect for personal websites, small and mid-sized
                    businesses that do not require all the resources of a
                    server.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    Why should transfer your domain name, web hosting to
                    Motherepos?
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingTwo"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    There are many different reasons. Our 24/7 Email, Chat &amp;
                    Whatsapp Support is always customers choose Motherepos. Also
                    our competitive prices — including a free
                    <Link to="/domains/domain-registration/">
                      Domain name registration
                    </Link>
                    and free domain transfer with web hosting plans. And if you
                    already have one or more of our services, you can also
                    transfer your domain, web hosting to us with one once place,
                    it's easier to manage.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                  >
                    Can I upgrade or downgrade the plan?
                  </button>
                </h2>
                <div
                  id="flush-collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingThree"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    Yes, you can easily upgrade or dowgrade to one of our web
                    hosting plans at anytime before due invoice generate.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingfour">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapsefour"
                    aria-expanded="false"
                    aria-controls="flush-collapsefour"
                  >
                    Can I host multiple Web sites within one Shared Hosting
                    plan?
                  </button>
                </h2>
                <div
                  id="flush-collapsefour"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingfour"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    No! You have to choose
                    <Link to="/reseller/multi-domain-linux-hosting/">
                      Multi domain Linux hosting
                    </Link>
                    or
                    <Link to="/reseller/linux-reseller-hosting/">
                      Linux Reseller hosting
                    </Link>
                    plans allow you to host more than one Website, by adding
                    Customers and domains through your hosting control panel
                    i.e. Plesk.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingfive">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapsefive"
                    aria-expanded="false"
                    aria-controls="flush-collapsefive"
                  >
                    Is there a Money Back Guarantee?
                  </button>
                </h2>
                <div
                  id="flush-collapsefive"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingfive"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    Yes, we offer a 100% Risk Free, 30 day Money Back Guarantee
                    for all
                    <Link to="/hosting/windows-web-hosting/">
                      web hosting plans
                    </Link>
                    .
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default FaqLinuxHosting
