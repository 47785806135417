import React from "react"
import Layout from "../../components/layout"
import MysqlDatabasePage from "../../components/cloud/mysql-database/index"
const MysqlDatabase = () => {
  return (
    <Layout>
      <MysqlDatabasePage />
    </Layout>
  )
}

export default MysqlDatabase
